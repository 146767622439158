import {TAB} from '../../../../commons/constants/navigation'
import {isCalendarLayout, getComponentConfig} from '../selectors/settings'
import {DesignSection} from '../../../../commons/enums'
import {GetState} from '../types/state'
import {calendarSettingsSectionChanged, calendarSettingsTabChanged} from './calendar-layout'

export const SETTINGS_TAB_CHANGED = 'SETTINGS_TAB_CHANGED'

export const settingsTabChanged = (tab: TAB) => (dispatch: Function, getState: GetState) => {
  dispatch({
    type: SETTINGS_TAB_CHANGED,
    payload: tab,
  })

  if (isCalendarLayout(getComponentConfig(getState()))) {
    dispatch(calendarSettingsTabChanged(tab))
  }
}

export const settingsSectionChanged = (id: DesignSection) => (dispatch: Function, getState: GetState) => {
  if (isCalendarLayout(getComponentConfig(getState()))) {
    dispatch(calendarSettingsSectionChanged(id))
  }
}
