import {WidgetData} from '@wix/events-types'
import {
  addQueryParams,
  EventsHttpClient,
  LIST_LAYOUT,
  RECURRING_FILTER,
  stringifyParams,
  WIDGET_TYPE,
} from '@wix/wix-events-commons-statics'

export interface GetWidgetDataParams {
  compId: string
  language: string
  locale: string
  tz: string
  viewMode: string
  members: boolean
  paidPlans: boolean
  responsive: boolean
  showcase: boolean
  widgetType?: WIDGET_TYPE
  listLayout?: LIST_LAYOUT
  recurringFilter?: RECURRING_FILTER
}

export const getWidgetData = (api: EventsHttpClient) => async ({
  responsive = false,
  locale,
  language,
  compId,
  viewMode,
  members,
  paidPlans,
  widgetType,
  listLayout,
  recurringFilter,
  showcase = false,
  tz,
}: GetWidgetDataParams): Promise<WidgetData> =>
  api.get<WidgetData>(
    addQueryParams(
      `/html/v2/widget-data`,
      stringifyParams({
        compId,
        locale: language,
        regional: locale,
        viewMode,
        members,
        paidPlans,
        responsive,
        widgetType,
        listLayout,
        recurringFilter,
        showcase,
        tz,
      }),
    ),
  )
